import { useNavigate } from "@gatsbyjs/reach-router";
import { Button, Icon } from "@whitespace/components";
import * as defaultStyles from "@whitespace/gatsby-theme-smorgasbord/src/components/LoginDialogButtons.module.css";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

LoginDialogButtons.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function LoginDialogButtons({ styles = defaultStyles }) {
  const navigate = useNavigate();
  return (
    <>
      <Button
        onClick={() => {
          navigate("/login/bankid");
        }}
        className={clsx(styles.bankidButton)}
      >
        <Icon name="bankid" />
        Logga in med BankID
      </Button>
    </>
  );
}
