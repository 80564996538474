import { Link } from "@whitespace/components";
import { apartmentContext } from "@whitespace/gatsby-plugin-smorgasbord-fastapi";
import Logo from "@whitespace/gatsby-theme-smorgasbord/src/components/Logo";
import userContext from "@whitespace/gatsby-theme-smorgasbord/src/contexts/userContext";
import HeaderLogo from "@whitespace/gatsby-theme-wordpress-basic/src/components/HeaderLogo";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import * as defaultStyles from "./Header.module.css";

Header.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function Header({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const { logout } = useContext(userContext);
  const { apartments, selectedApartment, selectApartment } =
    useContext(apartmentContext);
  const { t } = useTranslation();

  const options = apartments?.map((apartment) => ({
    value: apartment.id,
    label: apartment.address.street,
  }));

  const value = options?.find(
    (option) => option.value === selectedApartment?.id,
  );

  return (
    <header className={clsx(styles.component, className)} {...restProps}>
      <div className={clsx(styles.header)}>
        <HeaderLogo align="left" components={{ Logo }} linkTo="/" />
        <div className={styles.menuContainer}>
          {apartments.length > 1 && (
            <div>
              <label htmlFor="building-selector" className={styles.label}>
                {t("selectApartmentLabel")}:
              </label>
              <Select
                className={styles.select}
                inputId="building-selector"
                placeholder="Byt lägenhet"
                value={value}
                isSearchable={false}
                options={options}
                onChange={({ value }) => {
                  selectApartment(value);
                }}
              />
            </div>
          )}
          <Link onClick={() => logout()} className={clsx(styles.signout)}>
            {t("logOut")}
          </Link>
        </div>
      </div>
    </header>
  );
}
