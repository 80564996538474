import "./CookieConsent.props.css";
import "./DashboardMenu.props.css";
import "./Form.props.css";

export default {
  layoutWidth: "40rem",
  layoutPadding: "2rem 1rem",
  loginPageBackground: "#297bb3",
  loginBoxWidth: "28rem",
  loginBoxDistance: "1rem",
  loginBoxSpacing: "clamp(2.00rem, 1.61rem + 1.95vw, 3.00rem)",
  loginBoxRadii: "0.25rem",
  loginBoxBorder: "1px solid #E0EBEF",
  loginBoxShadow:
    "0 15px 35px 0 rgb(60 66 87 / 8%), 0 5px 15px 0 rgb(0 0 0 / 12%)",
  loginBoxBackground: "#fff",
  loginBoxGap: "clamp(1.00rem, 0.80rem + 0.98vw, 1.50rem)",
  loginBoxLogoWidth: "75%",
  loginBoxLogoColor: "#297bb3",
  loginBoxHeadlineSize: "2rem",
  loginBoxHeadlineAlign: "center",
  loginBoxHeadlineWeight: "400",
  loginBoxHeadlineLetterSpacing: "-0.03px",
  loginBoxHeadlineLineHeight: "1",
  loginBoxHeadlineColor: "rgba(0, 0, 0, 0.8)",
  loginBoxDescriptionSize: "1rem",
  loginBoxDescriptionAlign: "center",
  loginBoxDescriptionWeight: "400",
  loginBoxDescriptionLetterSpacing: "inherit",
  loginBoxDescriptionLineHeight: "1.5",
  loginBoxDescriptionColor: "rgba(0, 0, 0, 0.8)",
  loginBoxAlertSize: "0.875rem",
  loginBoxAlertAlign: "center",
  loginBoxAlertWeight: "400",
  loginBoxAlertLetterSpacing: "inherit",
  loginBoxAlertLineHeight: "1.5",
  loginBoxAlertColor: "#72726d",
  loginNavDistance: "1rem",
  loginNavAlign: "center",
  loginNavGap: "0.5rem",
  loginBoxButtonGap: "1.5rem",
  loginBoxButtonIconSize: "2em",
  loginBoxButtonBackground: "#1c5d89",
  loginBoxButtonColor: "#fff",
  loginBoxButtonPadding: "1.25rem 1rem",
  loginBoxButtonShadow:
    "0 15px 35px 0 rgb(60 66 87 / 8%), 0 5px 15px 0 rgb(0 0 0 / 12%)",
  loginBoxButtonFontSize: "1.125rem",
  loginBoxButtonHoverBackground: "#103B66",
  loginBoxButtonHoverBoxShadow:
    "0 15px 35px 0 rgb(60 66 87 / 4%), 0 5px 15px 0 rgb(0 0 0 / 8%)",
  loginBoxButtonFocusBoxShadow:
    "0 15px 35px 0 rgb(60 66 87 / 14%), 0 5px 15px 0 rgb(0 0 0 / 16%), 0 0 0 3px blue",
  headerBackground: "#297bb3",
  headerColor: "#fff",
  headerPadding: "1.25rem",
  headerFlyOutToggleBackgroundColor: "#1c5d89",
  headerFlyOutToggleColor: "#fff",
  headerFlyOutTogglePadding: "1rem 1.25rem",
  sectionBackgroundColor: "#fff",
  headerTabsButtonActiveBorder: "1px solid #E5E7EB",
  sectionBorderBottom: "1px solid #E5E7EB",
};
