// extracted by mini-css-extract-plugin
export var compact = "DashboardMenu-module--compact--rDpyy";
export var content = "DashboardMenu-module--content--JC4tV";
export var description = "DashboardMenu-module--description--awXGt";
export var icon = "DashboardMenu-module--icon--ye0PK";
export var link = "DashboardMenu-module--link--7-GdG";
export var list = "DashboardMenu-module--list--FOCck";
export var primary = "DashboardMenu-module--primary--rN-Ad";
export var secondary = "DashboardMenu-module--secondary--Kf5Ye";
export var symbol = "DashboardMenu-module--symbol--gjw68";
export var title = "DashboardMenu-module--title--q5PkO";