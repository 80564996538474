import { Link } from "@whitespace/components";
import * as defaultStyles from "@whitespace/gatsby-theme-smorgasbord/src/components/LoginDialog.module.css";
import LoginDialogButtons from "@whitespace/gatsby-theme-smorgasbord/src/components/LoginDialogButtons";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import LogoSecondary from "./LogoSecondary";

LoginDialogStart.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function LoginDialogStart({ styles = defaultStyles }) {
  return (
    <>
      <div className={clsx(styles.logo)}>
        <LogoSecondary />
      </div>
      <LoginDialogButtons />
      <p className={clsx(styles.alert)}>
        Lämna aldrig ut dina inloggningsuppgifter och logga aldrig in på
        uppmaning av någon som kontaktar dig.
        <br />
        <br />
        Tillbaka till{" "}
        <Link to="https://www.salabostader.se">salabostader.se</Link>
      </p>
    </>
  );
}
