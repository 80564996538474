import * as defaultStyles from "@municipio/gatsby-theme-basic/src/components/modularity-modules/MenuModule.module.css";
import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import { DashboardMenu } from "@whitespace/components";
import { useMustacheData } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import usePages from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/pages";
import { getPage } from "@whitespace/gatsby-theme-wordpress-basic/src/utils/pageTree";
import clsx from "clsx";
import Mustache from "mustache";
import PropTypes from "prop-types";
import React from "react";

import * as dashboardMenuStyles from "../../../../@whitespace/components/DashboardMenu.module.css";

MenuModule.propTypes = {
  className: PropTypes.string,
  module: PropTypes.shape({
    menu: PropTypes.shape({ contacts: PropTypes.array }),
  }),
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.node,
  transformMenuItem: PropTypes.func,
};

function defaultTransformMenuItem(menuItem, { pages, mustacheData }) {
  let { connectedNode, label, description, url, target, extra, ...rest } =
    menuItem;

  let visibility = connectedNode?.node?.modSmorgasbord?.visibility;

  if (visibility) {
    let result;
    try {
      result = JSON.parse(Mustache.render(visibility, mustacheData));
    } catch {
      // Do nothing
    }
    if (!result) {
      return null;
    }
  }

  let icon = extra?.icon || rest?.icon;
  if (typeof icon === "string") {
    icon = { name: icon };
  }

  let { contentType: { node: { name: type = "custom" } = {} } = {}, id } =
    connectedNode?.node || {};

  let content = type === "page" ? getPage(pages, id) : {};

  return {
    appearance: "default",
    size: "normal",
    type,
    url,
    target: connectedNode?.node?.id ? target : "_blank",
    ...content,
    title: label,
    description: description || (content && content.description),
    ...extra,
    ...rest,
    icon,
  };
}

export default function MenuModule({
  className,
  module = {},
  styles = defaultStyles,
  title,
  transformMenuItem = defaultTransformMenuItem,
  ...restProps
}) {
  const pages = usePages();
  const {
    menu,
    modMenuOptions: { modMenuSource: source, modMenuCustomData: items },
  } = module;

  let mustacheData = useMustacheData();

  let menuItems = [];
  if (source && source === "custom") {
    menuItems = items?.map((item) => {
      return {
        appearance: item.appearance,
        compact: item.size === "compact",
        title: item.title,
        url: item.link.url,
        target: item.link.target,
        icon: { name: item.icon },
        description: item.description,
        download: item.downloadable ? item.filename || true : null,
      };
    });
  } else {
    menuItems = menu?.node?.menuItems?.nodes?.map((node) =>
      transformMenuItem(node, { pages, mustacheData }),
    );
  }

  menuItems = menuItems.filter(Boolean);

  return (
    <ModuleWrapper
      title={title}
      {...restProps}
      className={clsx(styles.component, className)}
    >
      <DashboardMenu styles={dashboardMenuStyles} items={menuItems} />
    </ModuleWrapper>
  );
}
