import { Section } from "@jfrk/react-heading-levels";
import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import { apartmentContext } from "@whitespace/gatsby-plugin-smorgasbord-fastapi";
import ApartmentDeclaration from "@whitespace/gatsby-plugin-smorgasbord-trimma/src/components/ApartmentDeclaration";
import {
  Tabs,
  Loading,
  ErrorMessage,
} from "@whitespace/gatsby-theme-smorgasbord/src/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useRef, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./SmorgasbordApartmentDeclarationModule.module.css";

SmorgasbordApartmentDeclarationModule.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function SmorgasbordApartmentDeclarationModule({
  styles = defaultStyles,
  className,
  title,
  ...restProps
}) {
  const { selectedApartment, pending } = useContext(apartmentContext);
  const { t } = useTranslation();

  if (pending) {
    return <Loading />;
  }

  return (
    <ModuleWrapper
      title={title}
      {...restProps}
      className={clsx(styles.component, className)}
    >
      <Section>
        {selectedApartment ? (
          <ApartmentDeclaration role="tabpanel" apartment={selectedApartment} />
        ) : (
          <ErrorMessage error={t("fastapi.error.noApartments")} />
        )}
      </Section>
    </ModuleWrapper>
  );
}
