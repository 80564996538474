import { Section } from "@jfrk/react-heading-levels";
import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import { apartmentContext } from "@whitespace/gatsby-plugin-smorgasbord-fastapi";
import FastAPIContext from "@whitespace/gatsby-plugin-smorgasbord-fastapi/src/contexts/FastAPIContext";
import { Loading } from "@whitespace/gatsby-theme-smorgasbord/src/components";
import { HTML } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useContext, useState, useEffect } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { useTranslation } from "react-i18next";
import "react-circular-progressbar/dist/styles.css";

import * as defaultStyles from "./SmorgasbordNkiModule.module.css";

SmorgasbordNkiModule.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.node,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function SmorgasbordNkiModule({
  styles = defaultStyles,
  title,
  description,
  ...restProps
}) {
  const { t } = useTranslation();
  const { pending } = useContext(FastAPIContext);
  const { selectedApartment } = useContext(apartmentContext);

  const [origogroupData, setOrigogroupData] = useState(null);
  const getOrigogroupData = async () => {
    try {
      let response = await window.fetch(
        `/api/nki/data/${selectedApartment?.id}`,
      );
      let { data } = await response.json();
      setOrigogroupData(data);
    } catch (err) {
      console.log(`Cannot make request to gepapi.origogroup.com, ${err}`);
    }
  };

  useEffect(() => {
    if (selectedApartment) {
      getOrigogroupData();
    }
  }, [selectedApartment]);

  if (pending) {
    return <Loading />;
  }

  return (
    <ModuleWrapper
      title={title && <div className={clsx(styles.title, "h1")}>{title}</div>}
      {...restProps}
      className={styles.title}
    >
      <Section>
        {description && <HTML>{description}</HTML>}
        {origogroupData && (
          <div className={styles.wrapper}>
            <div className={styles.box}>
              <div className={styles.circle}>
                <CircularProgressbar
                  value={origogroupData.company}
                  text={`${origogroupData.company}%`}
                />
              </div>
              <p className={styles.circleTitle}>TOTALT</p>
            </div>
            <div className={styles.box}>
              <div className={styles.circle}>
                <CircularProgressbar
                  value={origogroupData.benchmark}
                  text={`${origogroupData.benchmark}%`}
                />
              </div>
              <p className={styles.circleTitle}>ANDRA HYRESVÄRDAR</p>
            </div>
            <div className={styles.box}>
              <div className={styles.circle}>
                <CircularProgressbar
                  value={origogroupData.team ? origogroupData.team : 0}
                  text={origogroupData.team ? `${origogroupData.team}%` : ""}
                />
              </div>
              <p className={styles.circleTitle}>DITT FÖRVALTNINGSOMRÅDE</p>
            </div>
          </div>
        )}
      </Section>
    </ModuleWrapper>
  );
}
