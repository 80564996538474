import { Router } from "@gatsbyjs/reach-router";
import { CGIGRP2LoginDialogRouter } from "@whitespace/gatsby-plugin-smorgasbord-cgi-grp2";
import LoginDialogError from "@whitespace/gatsby-theme-smorgasbord/src/components/LoginDialogError";
import React from "react";

export default function LoginDialogRouter() {
  return (
    <Router>
      <CGIGRP2LoginDialogRouter path="bankid/*" />
      <LoginDialogError default />
    </Router>
  );
}
